/*! (c) Andrea Giammarchi - ISC */
/* https://github.com/WebReflection/html-parsed-element */
const HTMLParsedElement = (() => {
    const DCL = "DOMContentLoaded";
    const init = new WeakMap();
    const queue = [];
    const isParsed = (el) => {
        do {
            if (el.nextSibling) return true;
        } while ((el = el.parentNode));
        return false;
    };
    const upgrade = () => {
        queue.splice(0).forEach((info) => {
            if (init.get(info[0]) !== true) {
                init.set(info[0], true);
                info[0][info[1]]();
            }
        });
    };
    document.addEventListener(DCL, upgrade);
    class HTMLParsedElement extends HTMLElement {
        static withParsedCallback(Class, name = "parsed") {
            const { prototype } = Class;
            const { connectedCallback } = prototype;
            const method = name + "Callback";
            const cleanUp = (el, observer, ownerDocument, onDCL) => {
                observer.disconnect();
                ownerDocument.removeEventListener(DCL, onDCL);
                parsedCallback(el);
            };
            const parsedCallback = (el) => {
                if (!queue.length) requestAnimationFrame(upgrade);
                queue.push([el, method]);
            };
            Object.defineProperties(prototype, {
                connectedCallback: {
                    configurable: true,
                    writable: true,
                    value() {
                        if (connectedCallback)
                            connectedCallback.apply(this, arguments);
                        if (method in this && !init.has(this)) {
                            const self = this;
                            const { ownerDocument } = self;
                            init.set(self, false);
                            if (
                                ownerDocument.readyState === "complete" ||
                                isParsed(self)
                            )
                                parsedCallback(self);
                            else {
                                const onDCL = () =>
                                    cleanUp(
                                        self,
                                        observer,
                                        ownerDocument,
                                        onDCL
                                    );
                                ownerDocument.addEventListener(DCL, onDCL);
                                const observer = new MutationObserver(() => {
                                    /* istanbul ignore else */
                                    if (isParsed(self))
                                        cleanUp(
                                            self,
                                            observer,
                                            ownerDocument,
                                            onDCL
                                        );
                                });
                                observer.observe(self.parentNode, {
                                    childList: true,
                                    subtree: true
                                });
                            }
                        }
                    }
                },
                [name]: {
                    configurable: true,
                    get() {
                        return init.get(this) === true;
                    }
                }
            });
            return Class;
        }
    }
    return HTMLParsedElement.withParsedCallback(HTMLParsedElement);
})();

customElements.define(
    "cookies-dialog",
    class extends HTMLParsedElement {
        #dialogNode;
        #bannerNode;
        #modalNode;
        #headerNode;
        #initialX;
        #initialY;
        #currentX;
        #currentY;
        #offsetX;
        #offsetY;
        #dragging;
        #eventListeners;
        #width;
        #height;
        #visible;
        #options;
        #returnAll;
        #withBanner;

        static get observedAttributes() {
            return [
                "visible",
                "title",
                "width",
                "height",
                "returnall",
                "withbanner"
            ];
        }

        constructor() {
            super();
            //const template = document.getElementById('cookiedialog').content;
            const template = document.createElement("template");
            template.innerHTML = `<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap" rel="stylesheet">
<style>
    .cookies-dialog-modal-layer {
        position: fixed;
        left: 0;
        top: 0;
        display: none;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 80, 117, 0.3);
        z-index: 100000;
        z-index: 100000;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .cookies-dialog-banner {
        font-family: 'Roboto', sans-serif;
        position: fixed;
        left: 0;
        bottom: 14px;
        width: 100%;
        z-index: 99000;
        box-sizing: border-box;
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: hidden;
        transform: translateY(0);
        animation-duration: 1s;
        font-size: 16px;
        pointer-events: none;
    }
    .cookies-dialog-animation { animation-name: cookies-dialog-animation }
    @keyframes cookies-dialog-animation {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to { opacity: 1 }
    }
    .cookies-dialog-banner-inner {
        display: grid;
        grid-template-columns: 64px 1fr 1fr 64px;
        grid-template-rows: 1fr 42px;
        grid-template-areas: "icon text text close"
                              "icon leftbuttons rightbuttons close";
        gap: 0 0;
        max-width: 1340px;
        margin: auto;
        background-color: white;
        box-sizing: border-box;
        border: 1px solid #fdb927;
        border-bottom: 4px solid #fdb927;
        color: black;
        padding: 24px 0;
        pointer-events: auto;
    }
    .cookies-dialog-banner-text {
        box-sizing: border-box;
        line-height: 1.25em;
        grid-area: text;
    }
    .cookies-dialog-banner-text p {
        margin: 0 0 5px 0;
    }
    .cookies-dialog-buttons-left-group {
        grid-area: leftbuttons;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: 10px;
    }
    .cookies-dialog-buttons-right-group {
        grid-area: rightbuttons;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .cookies-dialog-banner-icon {
        grid-area: icon;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .cookies-dialog-banner-close {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        grid-area: close;
        visibility: hidden;
    }
    .cookies-dialog-banner-close > a {
        width: 20px;
        height: 20px;
    }
    .cookies-dialog-dialog {
        font-family: 'Roboto', sans-serif;
        display: grid;
        width: 600px;
        height: 600px;
        background-color: #fff;
        position: absolute;
        box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
        border-radius: 0px;
        font-size: 16px;
        line-height: 24px;
        color: rgb(21, 41, 53);
        grid-template-columns: 1fr;
        grid-template-rows: 72px calc(100% - 132px) 60px;
    }
    .cookies-dialog-dialog header {
        display: block;
        margin: 0;
        padding: 0 40px 0 30px;
        background-color: white;
        color: rgb(0, 80, 117);
        box-sizing: border-box;
        font-weight: bold;
        width: 100%;
        height: 72px;
        line-height: 72px;
        position: relative;
        cursor: move;
        border-bottom: 2px solid #0090d1;
        font-size: 24px;
    }
    .cookies-dialog-banner a {
        cursor: pointer;
        color: #0090d1;
        font-weight: normal;
    }
    .cookies-dialog-dialog header > a {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 25px;
        right: 20px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        color: white;
        font-weight: normal;
    }
    .cookies-dialog-dialog footer {
        display: flex;
        height: 60px;
        width: 100%;
        background-color: rgb(242, 245, 248);
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
    }
    .cookies-dialog-banner button,
    .cookies-dialog-dialog footer button {
        text-transform: none;
        outline: none;
        color: white;
        border: none;
        padding: 0 20px;
        cursor: pointer;
        background: linear-gradient(-26.5651deg,rgb(0,195,234) 0%, rgb(0,144,209) 100% );
        border-radius: 2px;
        height: 100%;
        margin: 0 10px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
    }
    .cookies-dialog-banner button.secondary,
    .cookies-dialog-dialog footer button.secondary {
        background: white;
        color: #0090d1;
        border: 1px solid #0090d1;
    }
    .cookies-dialog-banner button {
        height: 32px;
        margin: 0 5px;
    }
    .cookies-dialog-banner button:first-child,
    .cookies-dialog-dialog footer button:first-child {
        margin-left: 0;
    }
    .cookies-dialog-banner button:last-child,
    .cookies-dialog-dialog footer button:last-child {
        margin-right: 0;
    }
    .cookies-dialog-dialog.cookies-dialog-with-banner footer #cookies-dialog-accept-all {
        display: none;
    }
    .cookies-dialog-dialog main {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #f1f1f1;
        box-sizing: border-box;
        padding: 10px;
        font-size: 14px;
    }
    .cookies-dialog-dialog main::-webkit-scrollbar {
        width: 12px; /* width of the entire scrollbar */
    }

    .cookies-dialog-dialog main::-webkit-scrollbar-track {
        background: #f1f1f1; /* color of the tracking area */
    }

    .cookies-dialog-dialog main::-webkit-scrollbar-thumb {
        background-color: #c1c1c1; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #f1f1f1; /* creates padding around scroll thumb */
    }
    .cookies-dialog-dialog main p {
        margin: 0 0 10px 0;
        padding: 0;
    }
    .cookies-dialog-dialog main > p {
        padding: 0 20px;
    }
    .cookies-dialog-dialog main p a {
        color: #26adff;
    }
    .cookies-dialog-dialog .options .option {
        position: relative;
    }
    .cookies-dialog-dialog .options .option {
        width: 100%;
        color: #005075;
        overflow: hidden;
    }
    .cookies-dialog-dialog .options .option .option-label {
        display: flex;
        justify-content: flex-start;
        background: #f2f5f8;
        cursor: pointer;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
        padding: 0 0 0 10px;
        border-bottom: 1px solid #c9d9e8;
        border-top: 1px solid white;
        font-weight: bold;
    }
    .cookies-dialog-dialog .options .option .option-label::before {
        content: "\\276F";
        width: 48px;
        height: 48px;
        text-align: center;
        transition: all .35s;
    }
    .cookies-dialog-dialog .options .option .option-content {
        max-height: 0;
        padding: 0 48px 0 60px;
        background: white;
        transition: all .35s;
        color: #aaaaaa;
    }
    .cookies-dialog-dialog .options .option input.option-toggler {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    .cookies-dialog-dialog .options .option input.option-toggler:checked ~ .option-content {
        max-height: 100vh;
    }
    .cookies-dialog-dialog .options .option input.option-toggler:checked + .option-label {
        background: white;
        border-bottom: none;
    }
    .cookies-dialog-dialog .options .option input.option-toggler:checked + .option-label::before {
        transform: rotate(-90deg);
    }
    .cookies-dialog-banner .close-x,
    .cookies-dialog-dialog .close-x {
        stroke: #0090d1;
        fill: transparent;
        stroke-linecap: round;
        stroke-width: 5;
      }
    .cookies-dialog-dialog .switch {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 16px;
        top: 16px;
        right: 16px;
    }
    .cookies-dialog-dialog .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .cookies-dialog-dialog .switch .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .cookies-dialog-dialog .switch .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .cookies-dialog-dialog .switch input:checked + .slider {
        background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    }
    .cookies-dialog-dialog .switch input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    .cookies-dialog-dialog .switch input:checked + .slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
    .cookies-dialog-dialog .switch .slider.round {
        border-radius: 16px;
    }
    .cookies-dialog-dialog .switch .slider.round:before {
        border-radius: 50%;
    }
</style>
<div class="cookies-dialog-modal-layer">
    <div id="cookies-dialog-dialog" class="cookies-dialog-dialog">
        <header>
            <span></span>
            <a id="cookies-dialog-close"><svg viewbox="0 0 40 40">
            <path class="close-x" d="M 10,10 L 30,30 M 30,10 L 10,30" />
          </svg></a>
        </header>
        <ul style="display:none"><slot name="cookies-dialog-option"></slot></ul>
        <main>
            <slot style="display:none" name="cookies-dialog-text"></slot>
            <div class="options"></div>
        </main>
        <footer>
            <div>
                <button id="cookies-dialog-refuse" class="secondary">Continua senza accettare</button>
                <button id="cookies-dialog-accept-all" class="secondary">Continua accettando tutti</button>
            </div>
            <div>
                <button id="cookies-dialog-accept-chosen" class="secondary">Salva</button>
            </div>
        </footer>
    </div>
</div>
<div id="cookies-dialog-banner" class="cookies-dialog-banner">
    <div class="cookies-dialog-banner-inner">
        <div class="cookies-dialog-banner-text">
            <slot style="display:none" name="cookies-banner-text"></slot>
        </div>
        <div class="cookies-dialog-buttons-left-group">
            <button class="secondary" id="cookies-banner-refuse">Continua senza accettare</button>
            <button class="secondary" id="cookies-banner-customize">Modifica preferenze</button>
        </div>
        <div class="cookies-dialog-buttons-right-group">
            <button class="secondary" id="cookies-banner-accept-all">Continua accettando tutti</button>
        </div>
        <div class="cookies-dialog-banner-icon">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAbCAYAAABr/T8RAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TiyKVCnYQEcxQnSyIijhKFYtgobQVWnUwufQLmjQkKS6OgmvBwY/FqoOLs64OroIg+AHi6OSk6CIl/i8ptIjx4Lgf7+497t4BQqPCVLNrAlA1y0jFY2I2typ2vyKAfoQQxIjETD2RXszAc3zdw8fXuyjP8j735+hT8iYDfCLxHNMNi3iDeGbT0jnvE4dZSVKIz4nHDbog8SPXZZffOBcdFnhm2Mik5onDxGKxg+UOZiVDJZ4mjiiqRvlC1mWF8xZntVJjrXvyFwbz2kqa6zSHEccSEkhChIwayqjAQpRWjRQTKdqPefiHHH+SXDK5ymDkWEAVKiTHD/4Hv7s1C1OTblIwBgRebPtjFOjeBZp12/4+tu3mCeB/Bq60tr/aAGY/Sa+3tcgRENoGLq7bmrwHXO4Ag0+6ZEiO5KcpFArA+xl9Uw4YuAV619zeWvs4fQAy1NXyDXBwCIwVKXvd4909nb39e6bV3w9UrXKbp00u+wAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+YFEA8VGLu1s68AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAACHklEQVRIx72Wz2sTQRTHPzNSe9Eo/oggCGJFsV7SCho82FNP/gNeSw/9D7x4zCXsSegh9hQvLR6CJz15WhELPQWNaRPYas1WQkkhlhiyNN3nobFtStLsbDd+b/v2zXz2zbz57igREUzV3kHcIpwdR12PEUbKGNzM4y8/g73O84U3qEcJ1HDBHvL5IdLwjsRG4eYK+s6oEVgbZVeyx6D7H0Mli/jDqth38O2nsNvnffw9OjEWfcWyavWHAmxZyHbUS920kU17QJKNlGwkOrCHFKzu0MgMKrGCujXTHW9YyIYXEbiaQ+pOd+zqLCoeQ92eRXU1swPruUCNNgBcQ8qWWbvuWshq7XRgKWeQlmdoDR5sZpBmWHArj1QWCadFpJA/sdH6gmUtfWiLYVRPI1VT8PY7ZCvP6ZSHUq5v1brn8Sm9IBJ5KaTsBQPLRqaHH4cmw8+XSGsQ2HeQ9VcBfPvfTK0AuVlkzTn5JyHf5gJYI8A4xJ5A+yM0iwHyp1APFlCXe4GbNv6nOYamcwuox1MHFwZ90FBfU8EnOTMNN16jrkwHH9NIId+9Y3tczSG/3YAzJOD+PPpeEjU5j7qWCDjOhR+HPq6hhpQMqmUCFT+yV+cnDHw8hRT3fVxLOYMYnZ4lpNBZnbaLuEtme/0rjeyA2vtwV/BDNIseA98J12iX3qJDQTtnPrT+FNGM8P91MYmS+rL4XwZc5KJU7DlqMslfN7TvjBcgKJQAAAAASUVORK5CYII="></img>
        </div>
        <div class="cookies-dialog-banner-close">
            <a id="cookies-banner-close"><svg viewbox="0 0 40 40"><path class="close-x" d="M 10,10 L 30,30 M 30,10 L 10,30" /></svg></a>
        </div>
    </div>
</div>`;
            this.attachShadow({ mode: "open" }).appendChild(
                template.content.cloneNode(true)
            );
            this.#dragging = false;
            this.#offsetX = 0;
            this.#offsetY = 0;
            this.#eventListeners = {};
            this.#visible = false;
            this.#options = [];
            this.#width = 600;
            this.#height = 600;
            this.#returnAll = true;
            this.#withBanner = false;

            this.#injectFont();
        }

        #injectFont() {
            if (!document.getElementById("injected-font")) {
                const head =
                    document.head || document.getElementsByTagName("head")[0];

                const style2 = document.createElement("link");
                style2.setAttribute("rel", "preconnect");
                style2.setAttribute("href", "https://fonts.googleapis.com");
                head.appendChild(style2);

                const style3 = document.createElement("link");
                style3.setAttribute("rel", "preconnect");
                style3.setAttribute("href", "https://fonts.gstatic.com");
                style3.setAttribute("crossorigin", "crossorigin");
                head.appendChild(style3);

                const style = document.createElement("link");
                style.setAttribute("rel", "stylesheet");
                style.id = "injected-font";
                style.setAttribute(
                    "href",
                    "https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap"
                );
                head.appendChild(style);
            }
        }

        parsedCallback() {
            this.#dialogNode = this.shadowRoot.getElementById(
                "cookies-dialog-dialog"
            );
            this.#bannerNode = this.shadowRoot.getElementById(
                "cookies-dialog-banner"
            );
            this.#modalNode = this.#dialogNode.parentNode;
            this.#headerNode =
                this.#dialogNode.getElementsByTagName("header")[0];

            this.#addEventListener(
                "touchstart",
                this.#dialogNode,
                this.#dragStart
            );
            this.#addEventListener("touchend", this.#dialogNode, this.#dragEnd);
            this.#addEventListener("touchmove", this.#modalNode, this.#drag);
            this.#addEventListener(
                "mousedown",
                this.#dialogNode,
                this.#dragStart
            );
            this.#addEventListener("mouseup", this.#dialogNode, this.#dragEnd);
            this.#addEventListener("mousemove", this.#modalNode, this.#drag);
            this.#addEventListener("click", this.#dialogNode, this.#onClicked);
            this.#addEventListener("click", this.#bannerNode, this.#onClicked);

            this.#getDialogTextNodes().forEach((textNode) => {
                this.addText(textNode.innerHTML);
            });

            this.#getBannerTextNodes().forEach((textNode) => {
                this.addBannerText(textNode.innerHTML);
            });

            this.#getOptionsNodes().forEach((optionNode, idx) => {
                const optionValue =
                    optionNode.getAttribute("option-value") ?? idx.toString();
                const optionMandatory =
                    (optionNode.getAttribute("option-mandatory") ?? "no") ==
                    "yes";
                const optionChecked =
                    (optionNode.getAttribute("option-checked") ?? "yes") ==
                        "yes" || optionMandatory;
                const optionTitle =
                    optionNode.getAttribute("option-title") ?? "";
                this.addOption(
                    optionTitle,
                    optionNode,
                    optionValue,
                    optionChecked,
                    optionMandatory
                );
            });

            this.width = this.getAttribute("width") ?? this.width;
            this.height = this.getAttribute("height") ?? this.height;
            this.title = this.getAttribute("title") ?? "";
            this.returnall = this.getAttribute("returnall") ?? true;
            this.withBanner = this.getAttribute("withbanner") ?? false;
            this.visible = this.getAttribute("visible") ?? false; // LAST
        }

        disconnectedCallback() {
            this.#eventListeners.forEach((data, eventName) => {
                data.node.removeEventListener(eventName, data.callback);
                data.node = null;
            });
        }

        addOption(title, label, value, checked, mandatory) {
            if (!this.#dialogNode) {
                return;
            }
            const optionsCount = this.#options.length;

            const mainNode = this.#dialogNode.getElementsByTagName("main")[0];
            const optionsNode = mainNode.getElementsByTagName("div")[0];
            const optionName = `option-${optionsCount}`;
            const tabName = `tab-${optionsCount}`;

            const optionNode = document.createElement("div");
            optionNode.classList.add("option");

            const optionInputToggler = document.createElement("input");
            optionInputToggler.setAttribute("type", "checkbox");
            optionInputToggler.classList.add("option-toggler");
            optionInputToggler.setAttribute("name", tabName);
            optionInputToggler.setAttribute("id", tabName);
            optionNode.appendChild(optionInputToggler);

            const optionLabel = document.createElement("label");
            optionLabel.classList.add("option-label");
            optionLabel.setAttribute("for", tabName);
            optionLabel.textContent = title;
            optionNode.appendChild(optionLabel);

            const optionContent = document.createElement("div");
            optionContent.classList.add("option-content");
            if (label instanceof Element) {
                this.#moveChildren(optionContent, label);
            } else {
                const textContainer = document.createElement("div");
                textContainer.innerHTML = label;
                optionContent.appendChild(textContainer);
            }
            optionNode.appendChild(optionContent);

            let toggleSwitch = null;
            let checkbox = null;

            if (!mandatory) {
                [toggleSwitch, checkbox] = this.#createToggleSwitch(
                    value,
                    optionName,
                    checked,
                    mandatory
                );
                optionNode.appendChild(toggleSwitch);
            }

            optionsNode.appendChild(optionNode);

            this.#options.push({
                name: optionName,
                value: value,
                mandatory: mandatory ?? false,
                checkbox: checkbox
            });
        }

        #createToggleSwitch(value, optionName, checked, mandatory) {
            const label = document.createElement("label");
            label.classList.add("switch");
            label.setAttribute("for", optionName);

            const checkbox = this.#createOptionCheckbox(
                value,
                optionName,
                checked,
                mandatory
            );

            const slider = document.createElement("span");
            slider.classList.add("slider");
            slider.classList.add("round");

            label.appendChild(checkbox);
            label.appendChild(slider);

            return [label, checkbox];
        }

        addText(text) {
            const optionsNode = this.#dialogNode.querySelector("div.options");
            const paragraphNode = document.createElement("p");
            paragraphNode.innerHTML = text;
            optionsNode.parentNode.insertBefore(paragraphNode, optionsNode);
        }

        addBannerText(text) {
            const textNode = this.#bannerNode.querySelector(
                "div.cookies-dialog-banner-text"
            );
            const paragraphNode = document.createElement("p");
            paragraphNode.innerHTML = text;
            textNode.appendChild(paragraphNode);
        }

        resetOptions() {
            this.#options.forEach((option) => {
                if (!option.mandatory) {
                    option.checkbox.checked = false;
                }
            });
        }

        setOptions(options) {
            this.#options
                .filter((option) => {
                    return (
                        !option.mandatory &&
                        Object.keys(options).includes(option.value)
                    );
                })
                .forEach((option) => {
                    option.checkbox.checked = !!options[option.value];
                });
        }

        #moveChildren(toNode, fromNode, containerCreator) {
            let dialogNode;
            if (containerCreator) {
                dialogNode = containerCreator();
                toNode.appendChild(dialogNode);
                toNode = dialogNode;
            }

            while (fromNode.childNodes.length > 0) {
                toNode.appendChild(fromNode.childNodes[0]);
            }
        }

        #createOptionCheckbox(value, name, checked, mandatory) {
            const checkBox = document.createElement("input");
            checkBox.type = "checkbox";
            checkBox.name = name;
            checkBox.id = name;
            checkBox.value = value;
            checkBox.checked = checked === true;
            if (mandatory) {
                checkBox.disabled = true;
            }
            return checkBox;
        }

        #onAcceptAll(evt) {
            evt.preventDefault();
            evt.stopPropagation();
            this.dispatchEvent(
                new CustomEvent("close", {
                    detail: { options: this.#collectAllOptions() }
                })
            );
            this.visible = false;
        }

        #onAcceptSelected(evt) {
            evt.preventDefault();
            evt.stopPropagation();
            this.dispatchEvent(
                new CustomEvent("close", {
                    detail: { options: this.#collectSelectedOptions() }
                })
            );
            this.visible = false;
        }

        #onAcceptMandatory(evt) {
            evt.preventDefault();
            evt.stopPropagation();
            this.dispatchEvent(
                new CustomEvent("close", {
                    detail: { options: this.#collectMandatoryOptions() }
                })
            );
            this.visible = false;
        }

        #onClicked(evt) {
            this.#compose(
                () => {
                    let node = this.#findNode(
                        evt.target,
                        (refNode) => !!refNode.getAttribute("option-name")
                    );
                    if (node) {
                        const optionName = node.getAttribute("option-name");
                        if (optionName) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            const checkBox =
                                this.shadowRoot.getElementById(optionName);
                            if (!checkBox.disabled) {
                                checkBox.checked = !checkBox.checked;
                            }
                            return true;
                        }
                    }
                    return false;
                },
                () => {
                    if (
                        evt.target.id === "cookies-dialog-accept-all" ||
                        evt.target.id === "cookies-banner-accept-all"
                    ) {
                        this.#onAcceptAll(evt);
                        return true;
                    }
                    return false;
                },
                () => {
                    if (
                        evt.target.id === "cookies-dialog-refuse" ||
                        evt.target.id === "cookies-banner-refuse"
                    ) {
                        this.#onAcceptMandatory(evt);
                        return true;
                    }
                    return false;
                },
                () => {
                    if (evt.target.id === "cookies-dialog-accept-chosen") {
                        this.#onAcceptSelected(evt);
                        return true;
                    }
                    return false;
                },
                () => {
                    if (evt.target.id === "cookies-banner-customize") {
                        if (this.#withBanner) {
                            this.#toggleBanner(false);
                            this.#toggleDialog(true);
                        }
                        return true;
                    }
                    return false;
                },
                () => {
                    let target = evt.target;
                    while (
                        target.tagName !== "A" &&
                        target !== this.shadowRoot
                    ) {
                        target = target.parentNode;
                    }
                    if (target) {
                        if (target.id === "cookies-dialog-close") {
                            if (!this.#withBanner) {
                                // this.#onAcceptMandatory(evt);
                                this.visible = false;
                                this.#toggleDialog(false);
                            } else {
                                this.#toggleDialog(false);
                                this.#toggleBanner(true);
                            }
                            this.dispatchEvent(new CustomEvent("hide"));
                            return true;
                        } else if (target.id === "cookies-banner-close") {
                            if (this.#withBanner) {
                                this.#onAcceptMandatory(evt);
                                return true;
                            }
                        }
                    }
                    return false;
                }
            );
        }

        showCustomizeDialog() {
            if (this.#withBanner) {
                if (this.#visible) {
                    this.#toggleBanner(false);
                    this.#toggleDialog(true);
                } else {
                    this.withBanner = false;
                    this.visible = true;
                    this.#toggleDialog(true);
                }
            } else if (!this.#visible) {
                this.visible = true;
                this.#toggleDialog(true);
            }
        }

        #compose(...fns) {
            for (let i = 0; i < fns.length; i++) {
                if (fns[i]()) {
                    break;
                }
            }
        }

        #findNode(refNode, predicate) {
            let node = refNode;
            while (node && node !== this.#dialogNode) {
                if (node.nodeType === 1 && predicate(node)) {
                    return node;
                }
                node = node.parentNode;
            }
            return null;
        }

        #toggleBanner(visible) {
            this.#bannerNode.style.visibility = visible ? "visible" : "hidden";
            if (visible) {
                this.#bannerNode.classList.add("cookies-dialog-animation");
            } else {
                this.#bannerNode.classList.remove("cookies-dialog-animation");
            }
        }

        #toggleDialog(visible) {
            this.#modalNode.style.display = visible ? "flex" : "none";
        }

        set visible(flag) {
            this.#visible = flag === true || flag === "yes";
            if (this.#dialogNode) {
                if (this.#withBanner) {
                    this.#toggleDialog(false);
                    this.#toggleBanner(this.#visible);
                } else {
                    this.#toggleDialog(this.#visible);
                }
            }
        }

        get visible() {
            return this.#visible;
        }

        set withBanner(flag) {
            this.#withBanner = flag === true || flag === "yes";
            if (this.#dialogNode) {
                if (this.#withBanner) {
                    this.#dialogNode.classList.add(
                        "cookies-dialog-with-banner"
                    );
                } else {
                    this.#dialogNode.classList.remove(
                        "cookies-dialog-with-banner"
                    );
                }
            }
        }

        get withBanner() {
            return this.#withBanner;
        }

        set title(text) {
            if (this.#headerNode) {
                this.#headerNode.getElementsByTagName("span")[0].textContent =
                    text;
            }
        }

        get title() {
            return this.#headerNode.getElementsByTagName("span")[0].textContent;
        }

        set width(w) {
            let value = 600,
                um = "px";
            const measure = this.#getCssMeasure(w);
            if (measure) {
                [value, um] = measure;
            }
            this.#width = value;
            if (this.#dialogNode) {
                this.#dialogNode.style.width = `${value}${um}`;
            }
        }

        get width() {
            return this.#width;
        }

        set height(h) {
            let value = 600,
                um = "px";
            const measure = this.#getCssMeasure(h);
            if (measure) {
                [value, um] = measure;
            }
            this.#height = value;
            if (this.#dialogNode) {
                this.#dialogNode.style.height = `${value}${um}`;
            }
        }

        get height() {
            return this.#height;
        }

        set returnall(flag) {
            this.#returnAll = flag === true || flag === "yes";
        }

        get returnall() {
            return this.#returnAll;
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (name === "visible") {
                this.visible = newValue;
            } else if (name == "title") {
                this.title = newValue;
            } else if (name == "width") {
                this.width = newValue;
            } else if (name == "height") {
                this.height = newValue;
            } else if (name === "returnall") {
                this.returnall = newValue;
            } else if (name === "withbanner") {
                this.withBanner = newValue;
            }
        }

        #collectAllOptions() {
            if (this.#returnAll) {
                return this.#options.reduce((obj, option) => {
                    obj[option.value] = true;
                    return obj;
                }, {});
            }
            return this.#options.map((option) => {
                return option.value;
            });
        }

        #collectSelectedOptions() {
            if (this.#returnAll) {
                return this.#options.reduce((obj, option) => {
                    obj[option.value] =
                        option.mandatory || option.checkbox.checked;
                    return obj;
                }, {});
            }
            return this.#options
                .filter((option) => {
                    return option.mandatory || option.checkbox.checked;
                })
                .map((option) => {
                    return option.value;
                });
        }

        #collectMandatoryOptions() {
            if (this.#returnAll) {
                return this.#options.reduce((obj, option) => {
                    obj[option.value] = option.mandatory;
                    return obj;
                }, {});
            }
            return this.#options
                .filter((option) => {
                    return option.mandatory;
                })
                .map((option) => {
                    return option.value;
                });
        }

        #getDialogTextNodes() {
            return this.#dialogNode
                .getElementsByTagName("main")[0]
                .getElementsByTagName("slot")[0]
                .assignedNodes();
        }

        #getBannerTextNodes() {
            return this.#bannerNode
                .getElementsByTagName("slot")[0]
                .assignedNodes();
        }

        #getOptionsNodes() {
            return this.#dialogNode
                .getElementsByTagName("ul")[0]
                .getElementsByTagName("slot")[0]
                .assignedNodes();
        }

        #addEventListener(eventName, node, callback) {
            const boundCallback = callback.bind(this);
            this.#eventListeners[eventName] = {
                node: node,
                callback: boundCallback
            };
            node.addEventListener(eventName, boundCallback, false);
        }

        #getCssMeasure(value) {
            if (typeof value === "number") {
                return Math.max(0, value) > 0 ? [value, "px"] : null;
            }
            const matches = value.match(
                /([0-9.]+)(cm|mm|Q|in|pc|pt|px|em|ex|ch|rem|lh|vw|vh|vmin|vmax)?/
            );
            if (matches) {
                return [parseFloat(matches[1]), matches[2] ?? "px"];
            }
            return null;
        }

        #dragStart(evt) {
            if (evt.type === "touchstart") {
                this.#initialX = evt.touches[0].clientX - this.#offsetX;
                this.#initialY = evt.touches[0].clientY - this.#offsetY;
            } else {
                this.#initialX = evt.clientX - this.#offsetX;
                this.#initialY = evt.clientY - this.#offsetY;
            }

            if (
                evt.target === this.#headerNode ||
                evt.target.parentNode === this.#headerNode
            ) {
                this.#dragging = true;
            }
        }

        #dragEnd /*evt*/() {
            this.#initialX = this.#currentX;
            this.#initialY = this.#currentY;
            this.#dragging = false;
        }

        #drag(evt) {
            if (this.#dragging) {
                evt.preventDefault();

                if (evt.type === "touchmove") {
                    this.#currentX = evt.touches[0].clientX - this.#initialX;
                    this.#currentY = evt.touches[0].clientY - this.#initialY;
                } else {
                    this.#currentX = evt.clientX - this.#initialX;
                    this.#currentY = evt.clientY - this.#initialY;
                }

                this.#offsetX = this.#currentX;
                this.#offsetY = this.#currentY;

                this.#setTranslate(
                    this.#currentX,
                    this.#currentY,
                    this.#dialogNode
                );
            }
        }

        #setTranslate(xPos, yPos, el) {
            el.style.transform =
                "translate3d(" + xPos + "px, " + yPos + "px, 0)";
        }
    }
);
